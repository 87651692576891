import { HeaderLink } from './interfaces/header-link';
import { Component, EventEmitter, OnDestroy, OnInit, Output } from '@angular/core';
import { environment, region_phrases, RegionNames } from 'src/environments/environment';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit {
  public links: HeaderLink[] = [
    {
      route: 'https://www.remateweb.com/',
      text: 'Transmissões Ao Vivo',
    },
    {
      route: 'https://www.remateweb.com/agenda',
      text: 'Agenda',
    },
    {
      route: 'https://www.remateweb.com/play',
      text: 'Remate Play',
    },
  ];

  public translations = region_phrases[environment.region_id as keyof RegionNames];

  public constructor() {
    //
  }

  public ngOnInit(): void {
    //
  }
}
