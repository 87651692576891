<div *ngIf="product">
  <div class="card">
    <div class="product-img">
      <img [src]="product.defaultImage" class="card-img-top rounded m-0" />
      <img src="/assets/circle-shield.svg" class="circle-shield" style="max-width: 44.25px" />
    </div>

    <div class="card-body p-1 position-relative">
      <p class="card-text m-0">{{ product.formatedCity() }}</p>

      <h5 class="card-title m-0 mt-2">{{ product.title }}</h5>
      <p class="m-0">
        <small
          ><small>{{ product.number }}</small></small
        >
      </p>
      <p class="total-price m-0" *ngIf="!!product.totalValue">{{ product.totalValue | currency: translations.currency }}</p>
      <p class="total-price m-0" *ngIf="!product.totalValue">{{ translations.to_consult }}</p>
      <p class="installment-price">
        {{ product.paymentConditions }}
      </p>
      <!-- <img src="/assets/frete.svg" class="img-frete" style="max-width: 44.25px" /> -->
    </div>
  </div>
</div>
