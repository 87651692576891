import { isPlatformBrowser, DOCUMENT } from '@angular/common';
import { Component, Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { ActivatedRoute, Event, NavigationEnd, NavigationError, NavigationStart, Router } from '@angular/router';
import { CookieService } from 'ngx-cookie-service';
import { environment } from 'src/environments/environment';

declare let gtag: Function;

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
@Injectable({
  providedIn: 'root'
})
export class AppComponent {
  public loading: boolean = true;

  public constructor(
    private router: Router,
    private route: ActivatedRoute,
    private cookieService: CookieService,
    @Inject(PLATFORM_ID) private readonly platformId: any,
    @Inject(DOCUMENT) private document: any
  ) {
    let shouldScrollTop = true;
    if(this.document.location.hostname.includes("bolivia"))
    {
      environment.region_id = "bo";
    }
    this.router.events.subscribe((event: Event): void => {
      if (event instanceof NavigationStart) {
        this.loading = true;
        if (event.navigationTrigger === 'popstate') shouldScrollTop = false;
        else shouldScrollTop = true;
      } else if (
        event instanceof NavigationEnd ||
        event instanceof NavigationEnd ||
        event instanceof NavigationError
      ) {
        this.loading = false;
      }
      if (event instanceof NavigationEnd) {
        if (isPlatformBrowser(this.platformId)) {
          if (shouldScrollTop) window.scrollTo(0, 0);
          gtag('config', 'G-4TKHVS4D0S', {
            page_path: event.urlAfterRedirects,
          });
          gtag('config', 'AW-845217015', {
            page_path: event.urlAfterRedirects,
          });
          const origin = this.route.snapshot.queryParams.origem;
          if (origin) this.cookieService.set('origin', origin, { expires: 30 });
        }
      }
    });
  }
}
