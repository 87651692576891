import { ExternalLinkHelper } from '../../helpers/external-link';
import { Component, OnInit } from '@angular/core';
import { environment, region_phrases, RegionNames } from 'src/environments/environment';

export type PageInfo = {
  title: string;
  route: string;
  url?: string;
};

export type InfoColumn = {
  title: string;
  pages: PageInfo[];
};

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
})
export class FooterComponent implements OnInit {
  public location = {
    city: 'Arapongas',
    address: 'Rodovia Br 369, Km 197 - Aricanduva',
    cep: '86700-970',
  };

  public currentYear = (new Date()).getFullYear();
  public translations = region_phrases[environment.region_id as keyof RegionNames];

  public contact = {
    phone: '(43) 3274-8585',
    fax: '(43) 3274-8500',
  };

  public infoColumns: InfoColumn[] = [
    {
      title: this.translations.platform,
      pages: [
        {
          title: this.translations.about_remate_agroshop,
          route: '/sobre',
        },
        {
          title: this.translations.privacy_policy_normal,
          route: '/politica-de-privacidade',
        },
        {
          title: this.translations.register,
          route: 'https://www.remateweb.com/cadastrar',
        },
        {
          title: this.translations.contact,
          route: '/contato',
        },
      ],
    },
    {
      title: 'Páginas',
      pages: [
        {
          title: this.translations.streams,
          route: 'https://www.remateweb.com/',
        },
        {
          title: this.translations.scale,
          route: 'https://www.remateweb.com/agenda',
        },
        {
          title: 'Remate Play',
          route: 'https://www.remateweb.com/play',
        },
        {
          title: this.translations.official_partners,
          route: '/parceiros',
        },
      ],
    },
  ];

  public socialMediaLinks = [
    {
      url: 'https://www.facebook.com/profile.php?id=61554898409693&mibextid=LQQJ4d',
      newTab: true,
      image: {
        src: 'assets/ic_facebook.png',
        alt: 'Facebook',
        width: '8px',
        height: 'auto'
      },
    },
    {
      url: 'https://www.instagram.com/remate.agroshop?igsh=ZjVqNWJzN2owanh2',
      newTab: true,
      image: {
        src: 'assets/ic_instagram.png',
        alt: 'Instagram',
        width: '15px',
        height: 'auto'
      },
    },
    {
      url: 'https://www.linkedin.com/company/remate-agroshop/',
      newTab: true,
      image: {
        src: 'assets/ic_linkedin.png',
        alt: 'Linkedin',
        width: '15px',
        height: 'auto'
      },
    },
    {
      url: 'https://www.youtube.com/@remateagroshop',
      newTab: true,
      image: {
        src: 'assets/ic_youtube.png',
        alt: 'Youtube',
        width: '15px',
        height: 'auto'
      },
    },
  ];

  public constructor(private externalLinkHelper: ExternalLinkHelper) {
    //
  }

  public ngOnInit(): void {
    //
  }

  public goTo(link: string, newTab: boolean = true): void {
    if(newTab)
    {
      this.externalLinkHelper.openInNewTab(link);
    }else{
      this.externalLinkHelper.openInTheSameTab(link);
    }
  }
}
