
export interface RegionNames {
  br: any;
  bo: any;
}

export const environment = {
  production: false,
  apiUrl: 'https://api.remateweb.com/api',
  recaptchaKey: '6Lc6k5UUAAAAAPONXhJAfJggxcGW0c4sws1ludKs',

  region_id: "br",
};

export const region_phrases: RegionNames = {
  br: {
    "currency"                              : "BRL",
    "previous"                              : "Anterior",
    "next"                                  : "Próximo",
    "next_bid"                              : "PRÓXIMO LANCE",
    "sold"                                  : "VENDIDO",
    "pre_bid_closed"                        : "PRÉ-LANCE ENCERRADO",
    "want_bid"                              : "QUERO LANÇAR",
    "bargain"                               : "NEGOCIAR",
    "go_back"                               : "Voltar",
    "by"                                    : "Por:",
    "invalid"                               : "Inválido",
    "message"                               : "Mensagem",
    "message_required"                      : "Mensagem é obrigatória.",
    "is_required"                           : "é obrigatório.",
    "message_sent"                          : "Mensagem enviada!",
    "unexpected_error"                      : "Erro inesperado!",
    "submit"                                : "Enviar",
    "sent"                                  : "Enviado!",
    "thanks_for_your_interest"              : "Obrigado pelo seu interesse.",
    "phone"                                 : "Telefone",
    "phone_cel"                             : "Telefone/Celular",
    "start"                                 : "INÍCIO",
    "end"                                   : "FIM",
    "order_by"                              : "Ordenar por:",
    "filter_by"                             : "Filtrar por:",
    "lowest_price"                          : "Menor preço",
    "highest_price"                         : "Maior preço",
    "date_start"                            : "DATA INÍCIO",
    "date_end"                              : "DATA ENCERRAMENTO",
    "events"                                : "Eventos",
    "name"                                  : "Nome",
    "full_name"                             : "Nome completo",
    "platform"                              : "Plataforma",
    "register"                              : "Cadastre-se",
    "contact"                               : "Contato",
    "streams"                               : "Transmissões",
    "scale"                                 : "Agenda",
    "current_price"                         : "Valor Atual",
    "current_bid"                           : "LANCE ATUAL",

    "quick_promotion"                       : "Promoção Relâmpago",
    "view_all"                              : "Ver todos",
    "official_partners"                     : "Parceiros Oficiais",
    "to_consult"                            : "A Consultar",

    "sell_with_us"                          : "VENDA CONOSCO",
    "about_remate_agroshop"                 : "Sobre a Remate Agroshop",
    "who_we_are"                            : "QUEM SOMOS",
    "about_phrase_1"                        : "Buscamos fomentar o mercado de maquinários e implementos agrícolas seminovos, utilizando a expertise adquirida ao longo dos 54 anos de atuação no segmento de leilões.",
    "about_phrase_2"                        : "Através de uma estrutura e plataforma que trazem soluções completas de comercialização, agregamos benefícios tanto para quem compra quanto para quem vende.",
    "about_phrase_3"                        : "Empresa líder no segmento de leilões da pecuária, atuando há mais de 54 anos na disseminação do melhoramento genético animal para o mundo, com mais de 1.100 leilões realizados em 2023.",
    "about_phrase_4"                        : "Primeiro streaming do Brasil a levar os principais leilões da pecuária brasileira para qualquer lugar do mundo pelas suas plataformas, oferecendo ferramentas que impulsionam a audiência, alcançando um público altamente qualificado.",
    "about_phrase_5"                        : "Proporcionar uma experiência completa de compra. Da escolha do produto à chegada dele na propriedade!",
    "about_phrase_6"                        : "Conectamos vendedores a uma audiência qualificada, viabilizando o escoamento de maquinários parados nos pátios e fazendas.",
    "get_to_know_us"                        : "CONHEÇA O GRUPO PROGRAMA LEILÕES",
    "partnership_with_insurances"           : "Parceria com seguradoras",
    "logistic_partners_guaranteed_shipping" : "Parceiros de logística que garantem a entrega dos produtos",
    "our_solutions"                         : "NOSSAS SOLUÇÕES",
    "be_a_remate_partner"                   : "SEJA UM PARCEIRO REMATE AGROSHOP",
    "advantages"                            : "VANTAGENS",
    "platform_exclusive_for_selling"        : "Plataforma exclusiva para comercialização",
    "personalized_marketing_strategies"     : "Estratégias de marketing digital personalizadas",
    "client_contact_through_platform"       : "Atendimento aos clientes dentro da plataforma",

    "get_in_touch_with_us"                  : "Entre em contato conosco",
    "contact_phrase_1"                      : "A equipe Remate Agroshop está a disposição para atendê-lo, entre em contato como preferir. Utilize nossos contatos para esclarecer dúvidas e/ou contratar a Remate Web para transmitir seu leilão de forma virtual com muita qualidade e responsabilidade.",
    "soon_we_will_get_in_touch"             : "Em breve entraremos em contato.",
    "try_again_or_contact_us"               : "Tente novamente ou entre em contato diretamente conosco",

    "virtual_bid"                           : "Leilão Virtual",
    "digital_shopping"                      : "Shopping Digital",
    "interested"                            : "Tenho interesse",

    "privacy_policy"                        : "POLÍTICA DE PRIVACIDADE",
    "privacy_policy_normal"                 : "Política de privacidade",
    "privacy_policy_phrase_1"               : "A Remate Agroshop leva a sua privacidade muito a sério. Por favor, leia o texto abaixo para saber mais sobre nossa Política de Privacidade.",
    "privacy_policy_phrase_2"               : "O QUE ESTA POLÍTICA DE PRIVACIDADE COBRE:",
    "privacy_policy_phrase_3"               : "Esta política de privacidade cobre o tratamento que a Remate Agroshop dá às informações capazes de identificar você, coletadas quando você está no site, aplicativo, módulo cliente ou sistema de pagamento.",
    "privacy_policy_phrase_4"               : "Esta política cobre também o tratamento dado pela Remate Agroshop às informações capazes de identificar o usuário que compartilhamos com os nossos parceiros de negócios com a Remate Agroshop.",
    "privacy_policy_phrase_5"               : "Esta política não se aplica às empresas que a Remate Agroshop seja proprietário ou controle, ou a indivíduos que não são empregados ou contratados pela Remate Agroshop.",
    "privacy_policy_phrase_6"               : "COLETA E USO DE INFORMAÇÕES",
    "privacy_policy_phrase_7"               : "A Remate Agroshop coleta informações pessoais capazes de identificá-lo quando você se registra para obter uma conta na Remate Agroshop, usa alguns produtos ou serviços da Remate Agroshop, visita páginas da Remate Agroshop e quando você entra em promoções ou concursos.",
    "privacy_policy_phrase_8"               : "A Remate Agroshop também pode receber informações que identifiquem o usuário advindas de parceiros de negócios.",
    "privacy_policy_phrase_9"               : "Uma vez registrado e utilizando nossos serviços, você passa a não ser anônimo para nós.",
    "privacy_policy_phrase_10"              : "A Remate Agroshop também recebe e armazena automaticamente informações em nossos servidores de suas atividades advindas de seu navegador, incluindo seu endereço IP, informações do cookie da Remate Agroshop e a página que você acessou.",
    "privacy_policy_phrase_11"              : "A Remate Agroshop usa suas informações para três propósitos gerais:",
    "privacy_policy_phrase_12"              : "Personalizar os anúncios e o conteúdo que você vê;",
    "privacy_policy_phrase_13"              : "Atender a seus pedidos por certos produtos ou serviços;",
    "privacy_policy_phrase_14"              : "Para contatá-lo sobre novos produtos ou ofertas especiais.",
    "privacy_policy_phrase_15"              : "COMPARTILHAMENTO DE INFORMAÇÕES",
    "privacy_policy_phrase_16"              : "A Remate Agroshop não irá vender ou alugar quaisquer de suas informações pessoais a qualquer terceiro.",
    "privacy_policy_phrase_17"              : "A Remate Agroshop poderá enviar informações capazes de identificar sobre você a pessoas, físicas ou jurídicas, quando:",
    "privacy_policy_phrase_18"              : "A Remate Agroshop obtiver o seu consentimento para compartilhar tais informações;",
    "privacy_policy_phrase_19"              : "A Remate Agroshop necessitar compartilhar suas informações para fornecer o produto ou serviço solicitado por você;",
    "privacy_policy_phrase_20"              : "A Remate Agroshop necessitar enviar informações para empresas que trabalham em benefício da Remate Agroshop provendo um serviço ou produto. Exceto se informado de outra forma, estas empresas não têm direito em usar as informações enviadas sobre você além do que for necessário para atender a Remate Agroshop;",
    "privacy_policy_phrase_21"              : "Em resposta a processos judiciais, ou acreditarmos que suas atitudes possam ter violado os Termos do Serviço da Remate Agroshop, as Normas de Uso da Remate Agroshop ou quaisquer outras das nossas diretrizes de uso para produtos ou serviços específicos;",
    "privacy_policy_phrase_22"              : "SEGURANÇA",
    "privacy_policy_phrase_23"              : "Suas informações da Conta são protegidas por senha para a sua segurança e privacidade. Em algumas áreas, a Remate Agroshop usa a criptografia SSL, o padrão da indústria, para proteger transmissões de dados.",
    "privacy_policy_phrase_24"              : "MUDANÇAS NA POLÍTICA DE PRIVACIDADE",
    "privacy_policy_phrase_25"              : "A Remate Agroshop pode alterar esta política de privacidade de tempos em tempos. Se fizermos alguma mudança substancial na maneira que usamos as suas informações pessoais, iremos notificá-lo com um anúncio em destaque em nossas páginas.",
    "privacy_policy_phrase_26"              : "TERMO DE CONSENTIMENTO",
    "privacy_policy_phrase_27"              : "Por meio do presente TERMO DE CONSENTIMENTO, o CLIENTE acima identificado autoriza de forma expressa a empresa REMATE TECNOLOGIA EM VENDAS LTDA - REMATE AGROSHOP a tratar, comunicar e compartilhar a(s) informação(ões) sobre seu(s) dado(s) para efetivação de sua(s) venda(s) e compra(s) realizadas através de leilão(ões) por esta intermediado(s) nos termos dos artigos 5º, XII; 7º, I, §5º e 8º da Lei Geral de Proteção de Dados - LGPD (Lei nº 13.709/2018) e artigo 5º, XII da Constituição Federal. Para tanto, DECLARA expressamente que está ciente que a(s) informação(ões) compartilhada(s) pela REMATE AGROSHOP objetiva de forma exclusiva a efetivação de sua(s) venda(s) e compra(s) realizada(s) através de leilão(ões), sem que, essa comunicação e/ou compartilhamento configure violação ao sigilo da(s) informação(ões) e/ou dado(s), não tendo a REMATE AGROSHOP qualquer responsabilidade pela utilização indevida ou desvio de finalidade da(s) informação(ões) e/ou dado(s) pelo(s) terceiro(s) anteriormente mencionado(s);",
    "privacy_policy_phrase_28"              : "Declara ainda, que após a conclusão da(s) venda(s) e compra(s) entre o CLIENTE e o terceiro cuja(s) da(s) informação(ões) e/ou dado(s) tenha(m) sido comunicado(s) e/ou compartilhado(s) é de total e exclusiva responsabilidade do CLIENTE comunicar imediatamente a(s) outra(s) parte(s) para a completa exclusão da(s) informação(ões) e/ou dado(s), isentando a REMATE AGROSHOP desde já de qualquer responsabilidade por eventual comunicação e/ou compartilhamento que tenha ocorrido após o encerramento da relação CLIENTE e terceiro(s).",
    "privacy_policy_phrase_29"              : "Por ser expressão da verdade, firmo o presente.",
  },
  bo: {
    "currency"                              : "BOB",
    "previous"                              : "Anterior",
    "next"                                  : "Próximo",
    "next_bid"                              : "PRÓXIMA OFERTA",
    "sold"                                  : "VENDIDO",
    "pre_bid_closed"                        : "PRE-OFERTA CERRADA",
    "want_bid"                              : "QUIERO OFERTAR",
    "bargain"                               : "NEGOCIAR",
    "go_back"                               : "Volver",
    "by"                                    : "Por:",
    "invalid"                               : "Inválido",
    "message"                               : "Mensaje",
    "message_required"                      : "El mensaje es obligatorio.",
    "is_required"                           : "es obligatorio.",
    "message_sent"                          : "¡Mensaje enviado!",
    "unexpected_error"                      : "¡Error inesperado!",
    "submit"                                : "Enviar",
    "sent"                                  : "¡Enviado!",
    "thanks_for_your_interest"              : "Gracias por tu interés.",
    "phone"                                 : "Teléfono",
    "phone_cel"                             : "Teléfono/Celular",
    "start"                                 : "COMENZAR",
    "end"                                   : "FIN",
    "order_by"                              : "Ordenar por:",
    "filter_by"                             : "Filtrar por:",
    "lowest_price"                          : "Precio más bajo",
    "highest_price"                         : "Precio más alto",
    "date_start"                            : "FECHA DE INICIO",
    "date_end"                              : "FECHA DE CIERRE",
    "events"                                : "Eventos",
    "name"                                  : "Nombre",
    "full_name"                             : "Nombre completo",
    "platform"                              : "Plataforma",
    "register"                              : "Registro",
    "contact"                               : "Contacto",
    "streams"                               : "Transmisiones",
    "scale"                                 : "Orden del día",
    "current_price"                         : "Valor Actual",
    "current_bid"                           : "OFERTA ACTUAL",

    "quick_promotion"                       : "Promoción relámpago",
    "view_all"                              : "Ver todo",
    "official_partners"                     : "Socios oficiales",
    "to_consult"                            : "Para ser consultado",

    "sell_with_us"                          : "VENDA CON NOSOTROS",
    "about_remate_agroshop"                 : "Sobre Remate Agroshop",
    "who_we_are"                            : "QUIÉNES SOMOS",
    "about_phrase_1"                        : "Buscamos fomentar el mercado de maquinaria e implementos agrícolas seminuevos, utilizando la experiencia adquirida a lo largo de los 54 años de actuación en el segmento de subastas.",
    "about_phrase_2"                        : "A través de una estructura y plataforma que brindan soluciones completas de comercialización, agregamos beneficios tanto para quien compra como para quien vende.",
    "about_phrase_3"                        : "Empresa líder en el segmento de subastas de ganadería, con más de 54 años de experiencia en la difusión del mejoramiento genético animal para el mundo, con más de 1.100 subastas realizadas en 2023.",
    "about_phrase_4"                        : "El primer streaming de Brasil en llevar las principales subastas de ganadería brasileña a cualquier lugar del mundo a través de sus plataformas, ofreciendo herramientas que impulsan la audiencia, alcanzando un público altamente calificado.",
    "about_phrase_5"                        : "Proporcionar una experiencia completa de compra. Desde la elección del producto hasta su llegada a la propiedad.",
    "about_phrase_6"                        : "Conectamos vendedores con una audiencia calificada, facilitando el movimiento de maquinaria detenida en patios y granjas.",
    "get_to_know_us"                        : "CONOZCA EL GRUPO PROGRAMA SUBASTAS",
    "partnership_with_insurances"           : "Asociación con aseguradoras",
    "logistic_partners_guaranteed_shipping" : "Socios de logística que garantizan la entrega de los productos.",
    "our_solutions"                         : "NUESTRAS SOLUCIONES",
    "be_a_remate_partner"                   : "SEA UN SOCIO DE REMATE AGROSHOP",
    "advantages"                            : "VENTAJAS",
    "platform_exclusive_for_selling"        : "Plataforma exclusiva para comercialización",
    "personalized_marketing_strategies"     : "Estrategias de marketing digital personalizadas",
    "client_contact_through_platform"       : "Atención a los clientes dentro de la plataforma",

    "get_in_touch_with_us"                  : "Contáctenos",
    "contact_phrase_1"                      : "El equipo de Remate Agroshop está a su disposición para atenderlo, contáctenos como prefiera. Utilice nuestros contactos para aclarar dudas y/o contratar a Remate Web para transmitir su subasta de forma virtual con mucha calidad y responsabilidad.",
    "soon_we_will_get_in_touch"             : "Pronto nos pondremos en contacto.",
    "try_again_or_contact_us"               : "Intente nuevamente o contáctenos directamente.",

    "virtual_bid"                           : "Subasta Virtual",
    "digital_shopping"                      : "Tienda Digital",
    "interested"                            : "Tengo interés",

    "privacy_policy"                        : "POLÍTICA DE PRIVACIDAD",
    "privacy_policy_normal"                 : "Política de privacidad",
    "privacy_policy_phrase_1"               : "Remate Agroshop toma muy en serio su privacidad. Por favor, lea el texto a continuación para conocer más sobre nuestra Política de Privacidad.",
    "privacy_policy_phrase_2"               : "LO QUE CUBRE ESTA POLÍTICA DE PRIVACIDAD:",
    "privacy_policy_phrase_3"               : "Esta política de privacidad cubre el tratamiento que Remate Agroshop da a la información capaz de identificarlo a usted, recopilada cuando está en el sitio, aplicación, módulo cliente o sistema de pago.",
    "privacy_policy_phrase_4"               : "Esta política también cubre el tratamiento dado por Remate Agroshop a la información capaz de identificar al usuario que compartimos con nuestros socios comerciales con Remate Agroshop.",
    "privacy_policy_phrase_5"               : "Esta política no se aplica a las empresas que Remate Agroshop posee o controla, ni a individuos que no sean empleados o contratados por Remate Agroshop.",
    "privacy_policy_phrase_6"               : "RECOLECCIÓN Y USO DE INFORMACIÓN",
    "privacy_policy_phrase_7"               : "Remate Agroshop recolecta información personal capaz de identificarlo cuando se registra para obtener una cuenta en Remate Agroshop, usa algunos productos o servicios de Remate Agroshop, visita páginas de Remate Agroshop y cuando participa en promociones o concursos.",
    "privacy_policy_phrase_8"               : "Remate Agroshop también puede recibir información que identifique al usuario proveniente de socios comerciales.",
    "privacy_policy_phrase_9"               : "Una vez registrado y utilizando nuestros servicios, deja de ser anónimo para nosotros.",
    "privacy_policy_phrase_10"              : "Remate Agroshop también recibe y almacena automáticamente información en nuestros servidores sobre sus actividades provenientes de su navegador, incluyendo su dirección IP, información de cookies de Remate Agroshop y la página que accedió.",
    "privacy_policy_phrase_11"              : "Remate Agroshop utiliza su información para tres propósitos generales:",
    "privacy_policy_phrase_12"              : "Personalizar los anuncios y el contenido que ve;",
    "privacy_policy_phrase_13"              : "Atender a sus pedidos de ciertos productos o servicios;",
    "privacy_policy_phrase_14"              : "Para contactarlo sobre nuevos productos u ofertas especiales.",
    "privacy_policy_phrase_15"              : "COMPARTICIÓN DE INFORMACIÓN",
    "privacy_policy_phrase_16"              : "Remate Agroshop no venderá ni alquilará ninguna de sus informaciones personales a terceros.",
    "privacy_policy_phrase_17"              : "Remate Agroshop puede enviar información capaz de identificarlo a personas, físicas o jurídicas, cuando:",
    "privacy_policy_phrase_18"              : "Remate Agroshop obtenga su consentimiento para compartir dicha información;",
    "privacy_policy_phrase_19"              : "Remate Agroshop necesite compartir su información para proporcionar el producto o servicio solicitado por usted;",
    "privacy_policy_phrase_20"              : "Remate Agroshop necesite enviar información a empresas que trabajan en beneficio de Remate Agroshop proporcionando un servicio o producto. Excepto si se informa lo contrario, estas empresas no tienen derecho a usar la información enviada sobre usted más allá de lo necesario para atender a Remate Agroshop;",
    "privacy_policy_phrase_21"              : "En respuesta a procesos judiciales, o si creemos que sus acciones pueden haber violado los Términos del Servicio de Remate Agroshop, las Normas de Uso de Remate Agroshop o cualquier otra de nuestras directrices de uso para productos o servicios específicos;",
    "privacy_policy_phrase_22"              : "SEGURIDAD",
    "privacy_policy_phrase_23"              : "Su información de la Cuenta está protegida por una contraseña para su seguridad y privacidad. En algunas áreas, Remate Agroshop utiliza cifrado SSL, el estándar de la industria, para proteger las transmisiones de datos.",
    "privacy_policy_phrase_24"              : "CAMBIOS EN LA POLÍTICA DE PRIVACIDAD",
    "privacy_policy_phrase_25"              : "Remate Agroshop puede modificar esta política de privacidad de vez en cuando. Si realizamos algún cambio sustancial en la manera en que usamos su información personal, lo notificaremos con un anuncio destacado en nuestras páginas.",
    "privacy_policy_phrase_26"              : "TÉRMINO DE CONSENTIMIENTO",
    "privacy_policy_phrase_27"              : "A través del presente TÉRMINO DE CONSENTIMIENTO, el CLIENTE arriba identificado autoriza de manera expresa a la empresa REMATE TECNOLOGÍA EN VENTAS LTDA - REMATE AGROSHOP a tratar, comunicar y compartir la(s) información(es) sobre su(s) dato(s) para la efectividad de su(s) venta(s) y compra(s) realizadas a través de subasta(s) intermediada(s) por esta en los términos de los artículos 5º, XII; 7º, I, §5º y 8º de la Ley General de Protección de Datos - LGPD (Ley nº 13.709/2018) y artículo 5º, XII de la Constitución Federal. Para ello, DECLARA expresamente que está consciente de que la(s) información(es) compartida(s) por REMATE AGROSHOP tiene como objetivo exclusivo la efectividad de su(s) venta(s) y compra(s) realizada(s) a través de subasta(s), sin que dicha comunicación y/o compartición constituya una violación al secreto de la(s) información(es) y/o dato(s), no teniendo REMATE AGROSHOP ninguna responsabilidad por el uso indebido o desvío de finalidad de la(s) información(es) y/o dato(s) por parte de los terceros mencionados anteriormente;",
    "privacy_policy_phrase_28"              : "Declara además, que tras la conclusión de la(s) venta(s) y compra(s) entre el CLIENTE y el tercero cuya(s) información(es) y/o dato(s) hayan sido comunicados y/o compartidos es de total y exclusiva responsabilidad del CLIENTE comunicar de inmediato a la(s) otra(s) parte(s) para la completa eliminación de la(s) información(es) y/o dato(s), eximiendo a REMATE AGROSHOP desde ya de cualquier responsabilidad por la eventual comunicación y/o compartición que haya ocurrido después del cierre de la relación CLIENTE y tercero(s).",
    "privacy_policy_phrase_29"              : "Por ser expresión de la verdad, firmo el presente."
  }
}
